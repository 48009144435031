import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { useIntl, Link } from 'gatsby-plugin-intl' // s4n

const NotFoundPage = () => {

    const intl = useIntl();
    const t = intl.formatMessage;
    // const langPrefix = `soft4net_` + __filename.replace(`.js`, ``).replaceAll(`/`, `_`); // === `src_components_nav`

    return (
      <Layout>
        <SEO
          seo={{
            metaTitle: "404: Not found",
            metaDescription: "It looks like you got lost",
          }}
        />
        {/* <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn't exist... the sadness.</p> */}
        <h1>404</h1>
        <h2>{ t({ id: `soft4net_error_http_404_page_not_found_title` }) }</h2>
        <h3>{ t({ id: `soft4net_error_http_404_page_not_found_content` }) }</h3>
        <Link to={`/`} title={`Home`} className="btn btn-primary mt-4">
            <i class="ti-angle-double-left"></i> { t({ id: `soft4net_page_home` }) }
        </Link>
      </Layout>
    )
  };

export default NotFoundPage;
